import React, { useEffect, useState, useContext } from "react";
import useStyles from "./style";

import Categories from "./categories/categories";
import Products from "./products/products";
import Outlets from "./outlets/outlets";

import { getBrandDetailsRequest } from "../../api/brand.api";
import useCancellablePromise from "../../api/cancelRequest";
import { useParams } from "react-router-dom";
import Loading from "../shared/loading/loading";

// Importing Toast related items
import { ToastContext } from "../../context/toastContext";
import { toast_actions, toast_types } from "../shared/toast/utils/toast"; 

const Brand = ({ brandId }) => {
  const classes = useStyles();

  const [brandDetails, setBrandDetails] = useState(null);
  const [brandIsFromFAndBCategory, setBrandIsFromFAndBCategory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // HOOKS
  const { cancellablePromise } = useCancellablePromise();
  
  // Accessing Toast dispatch from the context
  const dispatch = useContext(ToastContext);

  const getBrandDetails = async () => {
    setIsLoading(true);
    try {
      const data = await cancellablePromise(getBrandDetailsRequest(brandId));
      if (data.domain === "ONDC:RET11") {
        setBrandIsFromFAndBCategory(true);
      } else {
        setBrandIsFromFAndBCategory(false);
      }
      setBrandDetails(data);
    } catch (err) {
      console.error("Error fetching brand details:", err);

      // Dispatch a toast error message to notify the user
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: "Failed to load brand details. Please try again later.",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (brandId) {
      getBrandDetails();
    }
  }, [brandId]);

  if (isLoading || brandDetails === null) {
    return (
      <div className={classes.loader}>
        <Loading />
      </div>
    );
  } else {
    if (brandIsFromFAndBCategory) {
      return <Outlets brandId={brandId} brandDetails={brandDetails} />;
    } else {
      return (
        <>
          <Categories brandId={brandId} brandDetails={brandDetails} />
          <Products brandId={brandId} brandDetails={brandDetails} />
        </>
      );
    }
  }
};

export default Brand;

