import React, { useState, useContext, useEffect } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link, useHistory } from "react-router-dom";
import styles from "../../../styles/auth/auth.module.scss";
import Input from "../../shared/input/input";
import Button from "../../shared/button/button";
import AuthActionCard from "../auth-action-card/authActionCard";
import { ToastContext } from "../../../context/toastContext";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";
import logo from "../../../assets/images/AppLogo.svg"; // Assuming the logo path is the same
import { handleAuthPages } from "../../../utils/validateToken";

export default function ForgotPassword() {
  const auth = getAuth();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inlineError, setInlineError] = useState("");
  const dispatch = useContext(ToastContext);

  // Ensure guest users are handled when navigating to the forgot password page
  useEffect(() => {
    handleAuthPages("forgot-password");
  }, []);

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (!email) {
      setInlineError("Email cannot be empty");
      return;
    }

    setIsLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: "Password reset email sent!",
          },
        });
        history.push("/login"); // Redirect to login after successful reset
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage;

        switch (errorCode) {
          case "auth/user-not-found":
            errorMessage = "No account found with that email address.";
            break;
          case "auth/invalid-email":
            errorMessage = "The email address is not valid.";
            break;
          default:
            errorMessage = "Failed to send password reset email. Please try again.";
            break;
        }

        setInlineError(errorMessage);
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: errorMessage,
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const forgotPasswordForm = (
    <div className={styles.auth_form}>
      <form onSubmit={handleForgotPassword}>
        <Input
          id="email"
          name="email"
          type="email"
          placeholder="Enter your email address"
          label_name="Email"
          autoComplete="off"
          has_error={inlineError}
          onChange={(event) => {
            setEmail(event.target.value);
            setInlineError("");
          }}
          required
        />
        {inlineError && <p className={styles.error_message}>{inlineError}</p>}
        <div className="py-3 text-center">
          <Button
            isloading={isLoading ? 1 : 0}
            button_type="primary"
            button_hover_type="primary_hover"
            button_text="Reset Password"
            type="submit"
          />
        </div>
      </form>
    </div>
  );

  const navigation_link = (
    <div className="py-2 text-center">
      <Link to="/login" className={styles.navigation_link}>
        Back to Login
      </Link>
    </div>
  );

  return (
    <AuthActionCard
      action_form={forgotPasswordForm}
      navigation_link={navigation_link}
      logo={logo} // Include the logo
    />
  );
}

