import React, { useContext } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "../../../common/Radio";

import { AddressContext } from "../../../../context/addressContext";
import useCancellablePromise from "../../../../api/cancelRequest";
import axios from "axios";
import { AddCookie, removeCookie } from "../../../../utils/cookies";
import useStyles from "./style";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import GpsFixed from "@mui/icons-material/GpsFixed";
import LocationOn from "@mui/icons-material/LocationOn";
import { ToastContext } from "../../../../context/toastContext";
import { toast_actions, toast_types } from "../../../shared/toast/utils/toast";
import { Icon, Stack } from "@mui/material";
import palette from "../../../../utils/Theme/palette";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";

const SelectAddress = ({
  addresses,
  onClose,
  setAddAddress,
  setUpdateAddress,
  onSelectAddress,
}) => {
  const classes = useStyles();
  const { deliveryAddress, setDeliveryAddress, setBillingAddress } =
    useContext(AddressContext);
  const dispatch = useContext(ToastContext);

  // HOOKS
  const { cancellablePromise } = useCancellablePromise();

  const onSetDeliveryAddress = (id, descriptor, address) => {
    // fetchLatLongFromEloc(address?.areaCode);
    return {
      id,
      name: descriptor?.name || "",
      email: descriptor?.email || "",
      phone: descriptor?.phone || "",
      location: {
        address,
      },
    };
  };

  // use this function to fetch lat and long from eloc
  async function fetchLatLongFromEloc(eloc) {
    try {
      const { data } = await cancellablePromise(
        axios.get(
          `${process.env.REACT_APP_MMI_BASE_URL}mmi/api/mmi_place_info?eloc=${eloc}`
        )
      );
      const { latitude, longitude } = data;
      if (latitude && longitude) {
        AddCookie("LatLongInfo", JSON.stringify({ latitude, longitude }));
      } else {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message:
              "Cannot get latitude and longitude info for this pincode Please update the Address",
          },
        });
        setDeliveryAddress({});
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      });
    }
  }

  return (
    <Grid container spacing={3}>
      {/* delivery address list card */}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ maxWidth: 500, margin: "5px auto", marginBottom: 2 }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search for your location"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#000" }} />
                </InputAdornment>
              ),
            }} />
        </Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: "pointer" }} >
            <GpsFixed fontSize="small" sx={{ color: palette.primary.dark }} />
            <Typography color={palette.primary.dark}>
              Use your current location
            </Typography>
          </Stack>
          <Typography
            sx={{
              backgroundColor: "#f9f9f9",
              borderRadius: 50,
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "solid 1px #ccc",
              color: "#bbb"
            }}>
            OR
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={setAddAddress}
          >
            <LocationOn fontSize="small" sx={{ color: palette.primary.dark }} />
            <Typography color={palette.primary.dark}>
              Location on Map
            </Typography>
          </Stack>
        </Stack>
        {addresses && addresses.length > 0 && (
          <>
            <Typography mt={2} color="GrayText">
              Recent Locations
            </Typography>
            <FormControl className={classes.formControlRoot}>
              {addresses.map((delivery_address, ind) => {
                const { id, descriptor, address } = delivery_address;
                return (
                  <div
                    key={`address-radio-button-${ind}`}
                    className={classes.selectAddressRadioContainer}
                  >
                    <FormControlLabel
                      className={classes.formControlLabel}
                      onClick={() => {
                        setDeliveryAddress(() =>
                          onSetDeliveryAddress(id, descriptor, address)
                        );
                        AddCookie(
                          "delivery_address",
                          JSON.stringify(
                            onSetDeliveryAddress(id, descriptor, address)
                          )
                        );
                        setBillingAddress();
                        removeCookie("billing_address");
                        onSelectAddress(
                          onSetDeliveryAddress(id, descriptor, address)
                        );
                        onClose();
                      }}
                      control={<Radio checked={deliveryAddress?.id === id} />}
                      label={
                        <div>
                          <Typography variant="body1">
                            {address?.street ? address.street : address?.door},{" "}
                            {address?.city} {address?.state}
                          </Typography>
                          <Typography variant="body1">
                            {address?.areaCode}
                          </Typography>
                        </div>
                      }
                    />
                    {/* <Button
                      className={classes.editAddressButton}
                      variant="text"
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        setUpdateAddress({
                          id,
                          name: descriptor?.name,
                          email: descriptor?.email,
                          phone: descriptor?.phone,
                          areaCode: address?.areaCode,
                          city: address?.city,
                          door: address?.door,
                          state: address?.state,
                          street: address?.street,
                          tag: address?.tag,
                          lat: address?.lat,
                          lng: address?.lng,
                        });
                      }}
                    >
                      edit
                    </Button> */}
                  </div>
                );
              })}
            </FormControl>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SelectAddress;
