import React, { useEffect, useRef, useState, useContext } from "react";
import axios from "axios";
import useStyles from "./style";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";

import logo from "../../../assets/images/AppLogo.svg";
import { ReactComponent as LocationIcon } from "../../../assets/images/location.svg";
import { ReactComponent as AddressDownIcon } from "../../../assets/images/chevron-down.svg";
import { ReactComponent as ListIcon } from "../../../assets/images/list.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/search.svg";
import { ReactComponent as CartIcon } from "../../../assets/images/cart.svg";
import { ReactComponent as HeartIcon } from "../../../assets/images/heart.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/loggedInUser.svg";
import { useHistory, useLocation, Link } from "react-router-dom";

import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

import { restoreToDefault } from "../../../constants/restoreDefaultAddress";
import {
  getValueFromCookie,
  AddCookie,
  removeCookie,
  deleteAllCookies,
} from "../../../utils/cookies";
import { search_types } from "../../../constants/searchTypes";

import ModalComponent from "../../common/Modal";
import SelectAddress from "./selectAddress/selectAddress";
import AddressForm from "./addressForm/addressForm";
import useCancellablePromise from "../../../api/cancelRequest";
import { address_types } from "../../../constants/address-types";
import { SearchContext } from "../../../context/searchContext";
import { AddressContext } from "../../../context/addressContext";
import { getAllDeliveryAddressRequest } from "../../../api/address.api";
import { getUser, isLoggedIn } from "../../../utils/validateToken";
import { getCall } from "../../../api/axios";

import { categoryList } from "../../../constants/categories";
import { CartContext } from "../../../context/cartContext";
import { ToastContext } from "../../../context/toastContext";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";

const NavBar = ({ isCheckout = false, formData = { states: [], cities: [] } }) => {

  console.log("States in NavBar:", formData.states);
  console.log("Cities in NavBar:", formData.cities);
  
  // Safely destructure formData
  //let { states = [], cities = [] } = formData;

 // console.log("States in NavBar:", states);
 // console.log("Cities in NavBar:", cities);

  const classes = useStyles();
  const history = useHistory();
  const locationData = useLocation();
  const user = getUser();

  // Create query function to parse URL parameters
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();


  // Parse query parameters for states and cities
  let states = query.get("states")?.split(",") || [];
  let cities = query.get("cities")?.split(",") || [];

  console.log("Parsed States from Query:", states);
  console.log("Parsed Cities from Query:", cities);

  const { setSearchData, setLocationData } = useContext(SearchContext) || {};
  const { deliveryAddress, setDeliveryAddress } =
    useContext(AddressContext) || {};

  useEffect(() => {}, [locationData]);

  // STATES
  const [inlineError, setInlineError] = useState({
    location_error: "",
    search_error: "",
  });
  const [search, setSearch] = useState({
    type: search_types.PRODUCT,
    value: "",
  });
  const criteria = useRef();
  const [searchedLocationLoading, setSearchLocationLoading] = useState(false);
  const [searchProductLoading, setSearchProductLoading] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState({
    name: "",
    lat: "",
    lng: "",
  });
  const [selectAddressModal, setSelectAddressModal] = useState(false);
  const [toggleAddressModal, setToggleAddressModal] = useState({
    actionType: "",
    toggle: false,
    address: restoreToDefault(),
  });
  const [addressList, setAddressList] = useState([]);
  const [fetchDeliveryAddressLoading, setFetchDeliveryAddressLoading] =
    useState(false);
  const [toggleLocationListCard, setToggleLocationListCard] = useState(false);
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const openUserMenu = Boolean(anchorElUserMenu);
  const [anchorElCaregoryMenu, setAnchorElCategoryMenu] = useState(null);
  const openCategoryMenu = Boolean(anchorElCaregoryMenu);
  const { cartItems } = useContext(CartContext);

  const dispatch = useContext(ToastContext);

  const { cancellablePromise } = useCancellablePromise();

  const handleClickUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null);
  };

  const handleClickCategoryMenu = (event) => {
    setAnchorElCategoryMenu(event.currentTarget);
  };
  const handleCloseCategoryMenu = (routeName) => {
    console.log("handleCloseCategoryMenu==>>>>")
    setAnchorElCategoryMenu(null);
    if (routeName) {
      const searchName = query.get("s");
      const subCategoryName = query.get("sc");
      const params = new URLSearchParams({});
      if (searchName) {
        params.set("s", searchName);
      }
      if (routeName) {
        params.set("c", routeName);
      }
      if (subCategoryName) {
        params.set("sc", subCategoryName);
      }
      if (locationData.pathname !== "/application/products") {
        history.push({
          pathname: "/application/products",
          search: params.toString(),
        });
      } else {
        history.replace({
          pathname: locationData.pathname,
          search: params.toString(),
        });
      }
    }
  };

  const fetchDeliveryAddress = async () => {
    setFetchDeliveryAddressLoading(true);
    try {
      const data = await cancellablePromise(getAllDeliveryAddressRequest());
      setAddressList(data);

      if (data == null || data.length === 0) {
        setSelectAddressModal(true);
      }

      if (deliveryAddress === undefined && data.length === 0) {
        setToggleAddressModal({
          actionType: "add",
          toggle: true,
          address: restoreToDefault(),
        });
      }
    } catch (err) {
      setAddressList([]);
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      });
    } finally {
      setFetchDeliveryAddressLoading(false);
    }
  };

  function getLastEnteredValues() {
    const searchProductName = query.get("s");
    let search_context = getValueFromCookie("search_context");
    if (search_context) {
      search_context = Object.assign({}, JSON.parse(search_context));
      setSearch(() => ({
        type: search_context?.search?.type || search_types.PRODUCT, // Ensure type has a default
        value: query.size > 0 && searchProductName ? searchProductName : "",
      }));
      setSearchedLocation(search_context.location);
      setSearchData(() => ({
        type: search_context?.search?.type || search_types.PRODUCT, // Ensure type has a default
        value: query.size > 0 && searchProductName ? searchProductName : "",
      }));
      setLocationData(() => search_context.location);
    }
    if (getValueFromCookie("delivery_address")) {
      const address = JSON.parse(getValueFromCookie("delivery_address"));
      if (address) {
        setDeliveryAddress(() => address);
      }
    }
  }

  console.log("searchedLocation", searchedLocation);
  useEffect(() => {
    getLastEnteredValues();
    fetchDeliveryAddress();
  }, []);

  // Define fetchLatLongFromEloc function
  const fetchLatLongFromEloc = async (locationData) => {
    const { name, location } = locationData;
    const { lat, lng, areaCode, city, state, tag } = location?.address;

    AddCookie("LatLongInfo", JSON.stringify({ lat, lng }));
    setSearchedLocation({
      name,
      lat,
      lng,
      pincode: areaCode,
      city,
      state,
      tag,
    });

    const search_context = {
      search: getValueFromCookie("search_context")?.search || "",
      location: {
        name,
        lat,
        lng,
        pincode: areaCode,
        city,
        state,
        tag,
      },
    };
    setLocationData(search_context.location);
    AddCookie("search_context", JSON.stringify(search_context));
  };

  const setCriteriaLatLng = () => {
    if (getValueFromCookie("search_context")) {
      let sc = JSON.parse(getValueFromCookie("search_context") || {});
      setSearchedLocation({
        name: sc.location.name,
        lat: sc.location.lat,
        lng: sc.location.lng,
        pincode: sc.location.pincode,
        city: sc.location.city,
        state: sc.location.state,
        tag: sc.location.tag,
      });
    }
    if (search.type === search_types.PRODUCT) {
      criteria.current = {
        search_string: search.value.trim(),
        delivery_location: `${searchedLocation?.lat},${searchedLocation?.lng}`,
      };
    }
    if (search.type === search_types.CATEGORY) {
      criteria.current = {
        category_id: search.value.trim(),
        delivery_location: `${searchedLocation?.lat},${searchedLocation?.lng}`,
      };
    }
    if (search.type === search_types.PROVIDER) {
      criteria.current = {
        provider_id: search.value.trim(),
        delivery_location: `${searchedLocation?.lat},${searchedLocation?.lng}`,
      };
    }
  };

  useEffect(() => {
    setCriteriaLatLng();
  }, [search]);

  const updateSearchParams = () => {
    console.log("updateSearchParams==>>")
    const categoryName = query.get("c");
    const subCategoryName = query.get("sc");
    const params = new URLSearchParams({});
    console.log("params==>>",params);
    if (locationData.pathname !== "/application/products") {
      history.push(`/application/products?s=${search.value}`);
    } else {
      if (search.value) {
        params.set("s", search.value);
      }
      if (states.length > 0) {
        params.set("states", states.join(","));
      }
      if (cities.length > 0) {
        params.set("cities", cities.join(","));
      }
      if (categoryName) {
        params.set("c", categoryName);
      }
      if (subCategoryName) {
        params.set("sc", subCategoryName);
      }
      history.replace({
        pathname: locationData.pathname,
        search: params.toString(),
      });
    }
  };

  /*const updateSearchParams = () => {
    const categoryName = query.get("c");
    const subCategoryName = query.get("sc");
    const params = new URLSearchParams({});
    if (locationData.pathname !== "/application/products") {
      history.push(`/application/products?s=${search.value}`);
    } else {
      if (search.value) {
        params.set("s", search.value);
      }
      if (categoryName) {
        params.set("c", categoryName);
      }
      if (subCategoryName) {
        params.set("sc", subCategoryName);
      }
      if (formData.states.length > 0) {
        params.set("states", formData.states.join(","));
      }
      if (formData.cities.length > 0) {
        params.set("cities", formData.cities.join(","));
      }
      history.replace({
        pathname: locationData.pathname,
        search: params.toString(),
      });
    }
  };*/
  return (
    <>
      <AppBar position="absolute">
        <Toolbar className={classes.headerContainer}>
          <img
            src={logo}
            alt="logo"
            className={classes.appLogo}
            onClick={() => {
              history.push("/application/products");
            }}
          />
          {!isCheckout && (
            <>
              <div
                className={classes.addressContainer}
                onClick={() => setSelectAddressModal(true)}
              >
                <LocationIcon />
                <Typography variant="body2" className={classes.addressTypo}>
                  Deliver to <b>{searchedLocation?.pincode}</b>
                </Typography>
                <AddressDownIcon />
              </div>
              <div className={classes.inputContainer}>
                <Paper component="form" className={classes.inputForm}>
                  <IconButton type="button" disabled aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    fullWidth
                    className={classes.inputBase}
                    placeholder="Search..."
                    inputProps={{ "aria-label": "Search..." }}
                    value={search?.value || ""}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        e.preventDefault();
                        setSearchData(() => search);
                        updateSearchParams();
                      }
                    }}
                    onChange={(e) => {
                      const searchValue = e.target.value;
                      let searchDataUpdate = Object.assign(
                        {},
                        JSON.parse(JSON.stringify(search))
                      );
                      searchDataUpdate.value = searchValue;
                      setSearch(searchDataUpdate);
                      const search_context = {
                        search: searchDataUpdate,
                        location: searchedLocation,
                      };
                      AddCookie(
                        "search_context",
                        JSON.stringify(search_context)
                      );
                    }}
                  />
                  <IconButton
                    className={classes.listIcon}
                    onClick={handleClickCategoryMenu}
                    id="basic-button-cat"
                    aria-controls={
                      openCategoryMenu ? "basic-menu-cat" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openCategoryMenu ? "true" : undefined}
                  >
                    <ListIcon />
                  </IconButton>
                  <Menu
                    className={classes.userMenu}
                    id="basic-menu-cat"
                    anchorEl={anchorElCaregoryMenu}
                    open={openCategoryMenu}
                    onClose={() => handleCloseCategoryMenu("")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button-cat",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {categoryList.map((cat, catIndex) => (
                      <MenuItem
                        selected={cat?.routeName === query.get("c")}
                        key={`cat-index-${catIndex}`}
                        onClick={() => handleCloseCategoryMenu(cat.routeName)}
                      >
                        {cat.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Paper>
              </div>
              <div className={classes.favourite}>
                <HeartIcon />
                <Typography variant="body2" className={classes.favouriteTypo}>
                  List
                </Typography>
              </div>
              <div className={classes.cart}>
                <Link to="/application/cart">
                  <Badge color="error" badgeContent={cartItems.length}>
                    <CartIcon />
                  </Badge>
                  <Typography variant="body2" className={classes.cartTypo}>
                    Cart
                  </Typography>
                </Link>
              </div>
              <div
                className={classes.user}
                id="basic-button"
                aria-controls={openUserMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openUserMenu ? "true" : undefined}
                onClick={handleClickUserMenu}
              >
                <UserIcon />
                <Typography variant="body2" className={classes.userTypo}>
                  {isLoggedIn() && !user.isAnonymous ? user.name : "User"}
                </Typography>
              </div>
              <Menu
                className={classes.userMenu}
                id="basic-menu"
                anchorEl={anchorElUserMenu}
                open={openUserMenu}
                onClose={handleCloseUserMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {isLoggedIn() && !user.isAnonymous ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        history.push(`/profile`);
                      }}
                    >
                      My Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push(`/application/orders`);
                      }}
                    >
                      Order History
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push(`/application/complaints`);
                      }}
                    >
                      Complaints
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push(`/change-password`);
                      }}
                    >
                      Change Password
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {}}>Support</MenuItem>
                    <MenuItem
                      onClick={() => {
                        history.push(`/testYourCatalogue`);
                      }}
                    >
                      Test your catalogue
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        deleteAllCookies();
                        localStorage.removeItem("product_list");
                        localStorage.removeItem("cartItems");
                        history.replace("/");
                      }}
                    >
                      Logout
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem
                    onClick={() => {
                      history.push(`/login`);
                    }}
                  >
                    Login / Sign Up
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
          {isCheckout && (
            <>
              <div className={classes.inputContainer}></div>
              <div className={classes.user}>
                <UserIcon />
                <Typography variant="body2" className={classes.userTypo}>
                  User
                </Typography>
              </div>
            </>
          )}
        </Toolbar>
        {(selectAddressModal ||
          (deliveryAddress === undefined && addressList.length > 0)) && (
          <ModalComponent
            open={selectAddressModal || deliveryAddress === undefined}
            onClose={() => {
              setSelectAddressModal(false);
            }}
            title="Select a location"
          >
            <SelectAddress
              addresses={addressList}
              onSelectAddress={(pin) => {
                fetchLatLongFromEloc(pin);
                setSelectAddressModal(false);
              }}
              onClose={() => setSelectAddressModal(false)}
              setAddAddress={() => {
                setSelectAddressModal(false);
                setToggleAddressModal({
                  actionType: "add",
                  toggle: true,
                  address: restoreToDefault(),
                });
              }}
              setUpdateAddress={(address) => {
                setSelectAddressModal(false);
                setToggleAddressModal({
                  actionType: "edit",
                  toggle: true,
                  address: address,
                });
              }}
            />
          </ModalComponent>
        )}
        {toggleAddressModal.toggle && (
          <ModalComponent
            open={toggleAddressModal.toggle}
            onClose={() => {
              if (addressList.length > 0) {
                setToggleAddressModal({
                  actionType: "",
                  toggle: false,
                  address: restoreToDefault(),
                });
                setSelectAddressModal(true);
              }
            }}
            title={`${
              toggleAddressModal.actionType === "edit"
                ? `Update Delivery Address`
                : `Add Delivery Address`
            }`}
          >
            <AddressForm
              action_type={toggleAddressModal.actionType}
              address_type={address_types.delivery}
              selectedAddress={toggleAddressModal.address}
              onClose={() => {
                setToggleAddressModal({
                  actionType: "",
                  toggle: false,
                  address: restoreToDefault(),
                });
                setSelectAddressModal(true);
              }}
              onAddAddress={(address) => {
                setToggleAddressModal({
                  actionType: "",
                  toggle: false,
                  address: restoreToDefault(),
                });
                setAddressList([...addressList, address]);
                setSelectAddressModal(true);
              }}
              onUpdateAddress={(address) => {
                const updatedAddress = addressList.map((d) => {
                  if (d.id === address.id) {
                    return address;
                  }
                  return d;
                });
                setAddressList(updatedAddress);
                setToggleAddressModal({
                  actionType: "",
                  toggle: false,
                  address: restoreToDefault(),
                });
                setSelectAddressModal(true);
              }}
            />
          </ModalComponent>
        )}
      </AppBar>
    </>
  );
};

export default NavBar;
