import React from "react";
import { Link, Typography } from "@mui/material";
import moment from "moment";

import AppLayout from "../appLayout";
import styles from "../../styles/services/services.module.scss";

export default function CancellationRefundPolicy() {
  return (
    <AppLayout>
      <div className="container pt-5" style={{ paddingBottom: "5em" }}>
        <div className="row align-items-center">
          <div class="container">
            <div className={styles.subContainer}>
              <Typography
                variant="h1"
                sx={{
                  width: "100%",
                  justifyItems: "center",
                  textAlign: "center",
                }}
              >
                Refund & Order Cancellation Policy
              </Typography>
              <p className="mt-5">
                <strong>
                  Effective Date: {moment().format("DD MMM yyyy")}
                </strong>
              </p>

              <h3>Cancellation Policy</h3>
              <p>
                The cancellation policy for orders placed on OrderBazaar.in may
                vary depending on the seller.
              </p>

              <h5>1. Non-Cancellable & Non-Returnable Products:</h5>
              <p>
                For certain products, especially those sold by ONDC Network
                sellers, cancellations and returns may not be possible. This
                information will be clearly indicated on the product page. If a
                product is non-cancellable or non-returnable, the details will
                be specified in the product description, and no cancellations
                will be accepted after the order is placed.
              </p>

              <h5>2. Eligible Cancellations:</h5>
              <p>
                For orders that are eligible for cancellation, customers can
                cancel them anytime before the order is shipped. Once an order
                has been shipped, it can no longer be canceled. The cancellation
                window may vary by category. Orders cannot be canceled once the
                specified time has elapsed or if the order status has changed.
              </p>

              <h5>3. Cancellation Fees:</h5>
              <p>
                In some cases, customers may not be allowed to cancel the order
                for free after the specified time has elapsed, or if the status
                has changed, and a cancellation fee may be charged. The details
                about the time window and potential fees will be provided on the
                product page or order confirmation page.
              </p>
              <p>
                If the seller cancels an order due to unforeseen circumstances,
                a full refund will be initiated for prepaid orders.
              </p>

              <h5>4. Seller-Initiated Cancellations:</h5>
              <p>
                If the seller is unable to fulfill an order due to stock
                unavailability or other reasons, they may cancel the order. In
                such cases, a full refund will be issued to the customer for
                prepaid orders.
              </p>

              <h5>5. Right to Modify:</h5>
              <p>
                OrderBazaar reserves the right to accept or reject cancellation
                requests at its discretion. We also reserve the right to waive
                or modify the cancellation window or fees as necessary.
              </p>

              <h3>Return and Exchange Policy</h3>
              <p>
                For returnable products, customers can initiate a return within
                30 days of receiving the order, provided that the product is in
                its original condition. Returns will not be accepted after this
                period.
              </p>

              <p>
                To initiate a return or exchange, please contact our customer
                support team via email at{" "}
                <strong>
                  <Link href="mailto:support@orderbazaar.in">
                    support@orderbazaar.in
                  </Link>
                </strong>
                . Please include your order number and a brief explanation of
                the reason for the return or exchange. Our team will guide you
                through the process.
              </p>

              <h3>Refund Policy</h3>
              <p>
                Refund policies may vary depending on the product category and
                the seller's specific policies. The information outlined on the
                product page will take precedence over any general refund
                policy.
              </p>

              <h5>1. Eligibility for Refunds:</h5>
              <p>
                Refund eligibility is determined by the following conditions:
              </p>
              <ul>
                <li>
                  <strong>Non-Cancellable Items:</strong> Certain products are
                  non-cancellable once ordered. These items will be clearly
                  marked on the product page. Refunds for such items will only
                  be processed under specific conditions, such as if the product
                  was not delivered or if the seller initiates a cancellation
                  due to unforeseen circumstances.
                </li>
                <li>
                  <strong>Cancellable Items:</strong> For items that can be
                  canceled, refunds will be processed if the order is canceled
                  before shipment. Once the order is shipped, cancellation is
                  not possible, and a refund will not be initiated.
                </li>
                <li>
                  <strong>Seller Cancellations:</strong> If the seller cancels
                  the order due to stock unavailability or other reasons, a full
                  refund will be issued to the customer for prepaid orders.
                </li>
              </ul>

              <h5>2. Refund Process:</h5>
              <p>
                <strong>Processing Time:</strong> Refunds will be initiated once
                the cancellation is confirmed or the issue has been validated.
                The time taken for the refund to reflect in the customer's
                account may vary depending on the payment method used.
                Generally, refunds take 5 to 7 business days to reflect in the
                customer's bank account after the refund has been processed.
              </p>
              <p>
                <strong>Refund Method:</strong> Refunds will be processed back
                to the original payment method used during purchase.
              </p>

              <h5>3. General Conditions:</h5>
              <p>
                OrderBazaar reserves the right to modify or waive off the refund
                conditions at its discretion. In case of disputes or issues
                related to refunds, OrderBazaar’s decision will be considered
                final.
              </p>
              <p>
                For any specific inquiries or concerns regarding refunds,
                customers are encouraged to refer to the product page for
                detailed information or contact OrderBazaar’s customer support
                team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
