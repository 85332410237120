import Cookies from "js-cookie";
import { deleteAllCookies } from "../utils/cookies";

export function isLoggedIn() {
  const token = Cookies.get("token");
  
  // Check if the token exists and is valid (not expired)
  if (token) {
    const user = Cookies.get("user");
    
    if (user) {
      return true;
    } else {
      // If user data is missing, treat it as not logged in and clean up
      deleteAllCookies();
      return false;
    }
  }

  // If no token exists, ensure all cookies are cleared and return false
  deleteAllCookies();
  return false;
}

export function getUser() {
  if (isLoggedIn()) {
    const user = Cookies.get("user");
    if (user) {
      try {
        return JSON.parse(user);
      } catch (error) {
        console.error("Error parsing user data:", error);
        deleteAllCookies(); // Clean up cookies if there's an error
        return {};
      }
    }
  }
  return {};
}

export function handleAuthPages() {
  const user = getUser();

  if (user && user.isAnonymous) {
    // If the user is a guest (anonymous), remove all cookies
    deleteAllCookies();
    console.log("Deleted anonymous user cookies on auth page.");
  }
}
