import React, { Fragment, useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Typography } from "@mui/material";

import styles from "../../../styles/profile/profile.module.scss";
import { getAuth } from "firebase/auth";
import AppLayout from "../../appLayout";
import Button from "../../shared/button/button";
import { buttonTypes } from "../../shared/button/utils";
import { useHistory } from "react-router-dom"; // Import useNavigate
import useCancellablePromise from "../../../api/cancelRequest";
import { postCall } from "../../../api/axios";

export default function Profile() {
  const { cancellablePromise } = useCancellablePromise();

  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false); // To track if profile data is incomplete
  const auth = getAuth();

  const user = auth.currentUser;
  const history = useHistory();

  // Fetch the profile data from MongoDB
  useEffect(() => {
    if (auth.currentUser) {
      getUserProfileData();
    }
  }, [auth.currentUser]);

  const getUserProfileData = async () => {
    try {
      const data = await cancellablePromise(
        postCall(
          `/api/profile`,
          JSON.stringify({ userId: auth.currentUser?.uid })
        )
      );
      if (!data || !data.phone || !data.address) {
        setIsNewUser(true); // Mark as new user if profile fields are missing
      } else {
        setProfileData(data); // Set the fetched profile data
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // Loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // If new user, prompt for more information
  if (isNewUser) {
    return (
      <AppLayout>
        <div className="container pt-5">
          <h3>Welcome! Please complete your profile information:</h3>
          <Button
            button_type={buttonTypes.primary}
            onClick={() => history.push("/edit-profile")} // Redirect to edit profile page
            button_text="Complete Your Profile"
          />
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <div className="container pt-5">
        <div className="row align-items-center">
          <Typography variant="h2" fontWeight={500}>
            Profile
          </Typography>
          <div className="col-md-6 col-lg-2">
            <div className={styles.user_avatar}>
              {profileData?.photoURL ? (
                <img
                  src={profileData?.photoURL ?? "/path/to/default/avatar.png"}
                  alt="user_avatar"
                  className={styles.avatar_image}
                />
              ) : (
                <AccountCircleIcon sx={{ fontSize: 150, color: "GrayText" }} />
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-10">
            <p className={styles.user_name}>
              {profileData?.name || user.displayName}
            </p>
            <p className={styles.user_email}>{user.email}</p>
            <p className={styles.user_phone}>{profileData?.phone}</p>
            <p className={styles.user_address}>{profileData?.address}</p>
            <p className={styles.user_city}>{profileData?.city}</p>
            <p className={styles.user_state}>{profileData?.state}</p>
            <p className={styles.user_zip}>{profileData?.zipCode}</p>

            <Button
              button_type={buttonTypes.primary}
              onClick={() => history.push("/edit-profile")} // Redirect to edit profile page
              button_text="Edit Profile"
            />
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
