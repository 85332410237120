import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import TestYourCatalogue from "./components/TestYourCatalogue/testYourCatalogue";
import Application from "./components/application/application";
import Login from "./components/auth/login/login";
import SignUp from "./components/auth/sign-up/signUp";
import ForgotPassword from "./components/auth/forgot-password/forgotPassword"; // Import the ForgotPassword component
import ChangePassword from "./components/auth/change-password/changePassword";
import PageNotFound from "./components/page-not-found/pageNotFound";

import Home from "./components/home/home";
import Category from "./components/category/category";
import SubCategory from "./components/product/product";
import Products from "./components/products/products";
import AppLayout from "./components/appLayout";
import ProfileView from "./components/application/profile/ProfileView";
import ProfileEdit from "./components/application/profile/ProfileEdit";
import AboutUs from "./components/aboutUs";
import CancellationRefundPolicy from "./components/cancellationRefundPolicy";
import ContactUs from "./components/contactUs";
import SearchByCity from "./components/SearchByCity";
import TermsCondition from "./components/termsCondition";

export default function OndcRoutes() {
  return (
    <Router>
      <Switch>
        {/* Render the Products page when main URL ("/") is accessed */}
        <Route
          path={"/"}
          exact
          component={() => (
            <AppLayout>
              <Products />
            </AppLayout>
          )}
        />
        {/* Keep the other routes unchanged */}
        <Route
          path={"/category/:categoryName"}
          exact
          component={() => (
            <AppLayout>
              <Category />
            </AppLayout>
          )}
        />
        <Route
          path={"/category/:categoryName/:subCategoryName"}
          exact
          component={() => (
            <AppLayout>
              <SubCategory />
            </AppLayout>
          )}
        />
        <Route
          path={"/products"}
          exact
          component={() => (
            <AppLayout>
              <Products />
            </AppLayout>
          )}
        />
        {/* Authentication and Other Routes */}
        <Route path="/profile" exact component={ProfileView} />
        <Route path="/edit-profile" exact component={ProfileEdit} />
        <Route path={"/login"} component={Login} />
        <Route path={"/sign-up"} component={SignUp} />
        <Route path={"/forgot-password"} component={ForgotPassword} />{" "}
        {/* Add the Forgot Password route */}
        <Route path={"/application"} component={Application} />
        <Route path={"/change-password"} component={ChangePassword} />
        <Route path={"/testYourCatalogue"} component={TestYourCatalogue} />
        {/*Footer Route*/}
        <Route path="/about-us" component={AboutUs} />
        <Route
          path="/cancel-return-policy"
          component={CancellationRefundPolicy}
        />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/search-by-city" component={SearchByCity} />
        <Route path="/terms-and-condition" component={TermsCondition} />
        {/* Page Not Found Route */}
        <Route path="/page-not-found" component={PageNotFound} />
        <Route path="" component={() => <Redirect to="/page-not-found" />} />
      </Switch>
    </Router>
  );
}
