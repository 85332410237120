import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AddressContextProvider } from "../../context/addressContext";
import { CartContextProvider } from "../../context/cartContext";
import { SearchContextProvider } from "../../context/searchContext";
import PrivateRoute from "../../privateRoutes";
import Cart from "./cart/cart";
import Checkout from "../checkout/checkout";
import Orders from "../orders/orders";
import OrderDetails from "../orders/orderDetails/orderDetails";
import MyTickets from "./my-tickets/myTickets";
import AppLayout from "../appLayout";
import BrandRoutes from "../brand/BrandRoutes";
import ProductRoutes from "./product-list/ProductRoutes";
import ComplaintDetail from "./my-tickets/complaintDetail";
import SearchByCity from "../SearchByCity"; // Import added

export default function Application() {
  return (
    <CartContextProvider>
      <Switch>
        <AddressContextProvider>
          <SearchContextProvider>
            <Route path={"/application"} exact component={() => <Redirect to={"/application/products"} />} />
            <PrivateRoute exact path={"/application/products"}>
              <AppLayout>
                <ProductRoutes />
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute exact path={"/application/search-by-city"}>
              <AppLayout>
                <SearchByCity /> {/* Added route for SearchByCity */}
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={"/application/cart"}>
              <AppLayout>
                <Cart />
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={"/application/brand"}>
              <AppLayout>
                <BrandRoutes />
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute exact path={"/application/checkout"}>
              <AppLayout isCheckout={true}>
                <Checkout />
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={"/application/orders"}>
              <AppLayout>
                <Orders />
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={"/application/order/:orderId"}>
              <AppLayout>
                <OrderDetails />
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={"/application/complaints"}>
              <AppLayout>
                <MyTickets />
              </AppLayout>
            </PrivateRoute>
            <PrivateRoute path={"/application/complaint/:issueId"}>
              <AppLayout>
                <ComplaintDetail />
              </AppLayout>
            </PrivateRoute>
          </SearchContextProvider>
        </AddressContextProvider>
      </Switch>
    </CartContextProvider>
  );
}
