import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import AppLayout from "../../appLayout";
import Button from "../../shared/button/button";
import { buttonTypes } from "../../shared/button/utils";
import styles from "../../../styles/profile/profile.module.scss"; // Importing styles
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Image, PhotoCamera } from "@mui/icons-material";

export default function ProfileEdit() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    gender: "",
    occupation: "",
    about: "",
    photo: null,
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  // Fetch existing profile data from MongoDB based on Firebase UID
  useEffect(() => {
    if (user) {
      axios
        .post("/api/profile", { userId: user.uid })
        .then((response) => {
          setFormData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          setLoading(false);
        });
    }
  }, [user]);

  // Handle form data changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle file upload change
  const handleFileChange = (e) => {
    setFormData({ ...formData, photo: e.target.files[0] });
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file)); // Create image preview URL
    }
  };

  // Validate fields
  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.phone || !/^[0-9]{10}$/.test(formData.phone))
      tempErrors.phone = "Valid phone number is required";
    if (!formData.city) tempErrors.city = "City is required";
    if (!formData.state) tempErrors.state = "State is required";
    if (!formData.zipCode || !/^[0-9]{5,6}$/.test(formData.zipCode))
      tempErrors.zipCode = "Valid Zip Code is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("userId", user.uid);
      formDataToSubmit.append("name", formData.name);
      formDataToSubmit.append("email", formData.email);
      formDataToSubmit.append("phone", formData.phone);
      formDataToSubmit.append("address", formData.address);
      formDataToSubmit.append("city", formData.city);
      formDataToSubmit.append("state", formData.state);
      formDataToSubmit.append("zipCode", formData.zipCode);
      formDataToSubmit.append("gender", formData.gender);
      formDataToSubmit.append("occupation", formData.occupation);
      formDataToSubmit.append("about", formData.about);
      if (formData.photo) formDataToSubmit.append("photo", formData.photo);

      axios
        .post("/api/profile", formDataToSubmit, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          alert("Profile updated successfully");
          window.location.href = "/profile"; // Redirect to profile view page
        })
        .catch((error) => {
          console.error("Error updating profile:", error);
        });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AppLayout>
      <Grid size={{ xs: 6, md: 8 }}>
        <form
          onSubmit={handleSubmit}
          className={`container pt-5 ${styles.profile_form}`}
        >
          <Stack spacing={2} alignItems="center">
            {/* Image Upload */}
            {selectedImage && (
              <Box mt={2}>
                <Avatar
                  alt="Profile Picture"
                  src={selectedImage}
                  sx={{ width: 100, height: 100 }}
                />
              </Box>
            )}
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <PhotoCamera />
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
            </IconButton>

            <Stack direction="row" width={600} spacing={2} alignItems="center">
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                Name:
              </Typography>
              {/* Name */}
              <Box flex={1}>
                <TextField
                  type="text"
                  name="name"
                  className={`form-control ${styles.form_control}`}
                  value={formData.name}
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
                {errors.name && (
                  <span className={styles.error}>{errors.name}</span>
                )}
              </Box>
            </Stack>
            <Stack direction="row" width={600} spacing={2} alignItems="center">
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                Email:
              </Typography>
              {/* Email */}
              <Box flex={1}>
                <TextField
                  type="email"
                  name="email"
                  className={`form-control ${styles.form_control}`}
                  value={formData.email}
                  disabled
                  variant="outlined"
                />
              </Box>
            </Stack>

            <Stack direction="row" width={600} spacing={2} alignItems="center">
              {/* Phone */}
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                Phone:
              </Typography>
              <Box flex={1}>
                <TextField
                  type="text"
                  name="phone"
                  className={`form-control ${styles.form_control}`}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
                {errors.phone && (
                  <span className={styles.error}>{errors.phone}</span>
                )}
              </Box>
            </Stack>

            {/* Gender */}
            <Stack direction="row" width={600} spacing={2} alignItems="center">
              {/* Phone */}
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                Gender (optional):
              </Typography>
              <Box flex={1}>
                <Stack direction="row" spacing={2}>
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={formData.gender === "male"}
                      onChange={handleChange}
                    />{" "}
                    Male
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={formData.gender === "female"}
                      onChange={handleChange}
                    />{" "}
                    Female
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      value="other"
                      checked={formData.gender === "other"}
                      onChange={handleChange}
                    />{" "}
                    Other
                  </label>
                </Stack>
              </Box>
            </Stack>
            <Stack direction="row" width={600} spacing={2} alignItems="center">
              {/* Phone */}
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                Occupation (optional):
              </Typography>
              <Box flex={1}>
                {/* Occupation */}
                <TextField
                  type="text"
                  name="occupation"
                  className={`form-control ${styles.form_control}`}
                  value={formData.occupation}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Box>
            </Stack>
            <Stack direction="row" width={600} spacing={2} alignItems="center">
              {/* Phone */}
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                About Yourself (optional):
              </Typography>
              <Box flex={1}>
                {/* About */}
                <TextField
                  name="about"
                  className={`form-control ${styles.form_control}`}
                  value={formData.about}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Box>
            </Stack>
            <Stack direction="row" width={600} spacing={2} alignItems="center">
              {/* Phone */}
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                Address:
              </Typography>
              <Box flex={1}>
                <TextField
                  type="text"
                  name="address"
                  className={`form-control ${styles.form_control}`}
                  value={formData.address}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Box>
            </Stack>
            <Stack direction="row" width={600} spacing={2} alignItems="center">
              {/* Phone */}
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                City:
              </Typography>
              <Box flex={1}>
                <TextField
                  type="text"
                  name="city"
                  className={`form-control ${styles.form_control}`}
                  value={formData.city}
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
                {errors.city && (
                  <span className={styles.error}>{errors.city}</span>
                )}
              </Box>
            </Stack>

            {/* State */}
            <Stack direction="row" width={600} spacing={2} alignItems="center">
              {/* Phone */}
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                State:
              </Typography>
              <Box flex={1}>
                <TextField
                  type="text"
                  name="state"
                  className={`form-control ${styles.form_control}`}
                  value={formData.state}
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
                {errors.state && (
                  <span className={styles.error}>{errors.state}</span>
                )}
              </Box>
            </Stack>
            {/* Zip Code */}
            <Stack direction="row" width={600} spacing={2} alignItems="center">
              {/* Phone */}
              <Typography variant="h6" fontWeight={400} flex={0.6}>
                Zip Code:
              </Typography>
              <Box flex={1}>
                <TextField
                  type="text"
                  name="zipCode"
                  className={`form-control ${styles.form_control}`}
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
                {errors.zipCode && (
                  <span className={styles.error}>{errors.zipCode}</span>
                )}
              </Box>
            </Stack>

            <div className="text-center py-3">
              <Button
                button_type={buttonTypes.primary}
                button_text="Save"
                type="submit"
              />
            </div>
          </Stack>
        </form>
      </Grid>
    </AppLayout>
  );
}
