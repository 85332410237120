import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

// Initialize Firebase Authentication
const auth = getAuth(app);

// Automatically sign in anonymously if not logged in
auth.onAuthStateChanged((user) => {
  if (!user) {
    signInAnonymously(auth).catch((error) => {
      console.error("Anonymous sign-in failed:", error);
    });
  }
});

export { auth };

