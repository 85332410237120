import React from "react";
import { Link, Typography } from "@mui/material";
import moment from "moment";

import AppLayout from "../appLayout";
import styles from "../../styles/services/services.module.scss";

export default function TermsCondition() {
  return (
    <AppLayout>
      <div className="container pt-5" style={{ paddingBottom: "5em" }}>
        <div className="row align-items-center">
          <div className={styles.subContainer}>
            <Typography
              variant="h1"
              sx={{
                width: "100%",
                justifyItems: "center",
                textAlign: "center",
              }}
            >
              Terms and Conditions
            </Typography>
            <p className="mt-5">
              <strong>Effective Date:</strong> {moment().format("DD MMM yyyy")}
            </p>
            <p>
              Welcome to <Link href="#">OrderBazaar.in</Link>! These Terms and
              Conditions ("Terms") govern your access to and use of the{" "}
              <Link href="#">OrderBazaar.in</Link> platform (defined
              hereinafter). By registering, signing in, or simply accessing and
              browsing the OrderBazaar platform, you signify your absolute and
              unconditional consent to all the provisions of this agreement in
              their entirety.
            </p>

            <h4>Platform</h4>
            <p>
              <Link href="#">OrderBazaar.in</Link>, including related
              subdomains, mobile sites, and mobile applications (hereinafter
              referred to as "Platform" or "OrderBazaar"), is owned and operated
              by Orderbazaar e-Commerce Pvt. Ltd., a company incorporated under
              the laws of India, with its registered office at:
            </p>
            <p>
              Orderbazaar e-Commerce Pvt. Ltd.
              <br />
              E001, Bollineni Hillside - Phase 2, Nookampalayam Link Road,
              <br />
              Perumbakkam, Chennai - 600 100
            </p>

            <h4>Buyer and Seller Transaction and Communication</h4>
            <h5>1. Commercial/Contractual Terms</h5>
            <p>
              All commercial/contractual terms are offered by and agreed to
              between Buyers and Sellers alone. These terms include, but are not
              limited to, price, shipping costs, payment methods, payment terms,
              date, period and mode of delivery, warranties related to products
              and services, and after-sales services. OrderBazaar does not have
              any control or involvement in the offering or acceptance of such
              terms between Buyers and Sellers. All discounts and offers
              (including exchange offers) are provided by the Seller/Brand and
              not by OrderBazaar.
            </p>

            <h5>2. Order Placement and Acceptance</h5>
            <p>
              When a Buyer places an order with a Seller on the Platform, it is
              an offer to buy the product(s) from the Seller. This is not
              construed as the Seller's acceptance of the Buyer's offer. The
              Seller retains the right to cancel any order at its sole
              discretion, and the Buyer will be informed via email/SMS. If the
              Seller cancels an order, any transaction price paid by the Buyer
              will be refunded.
            </p>

            <h5>3. No Warranty on Product Specifics</h5>
            <p>
              OrderBazaar makes no representation or warranty regarding the
              specifics (such as quality, value, salability, etc.) of the
              products or services proposed to be sold or purchased on the
              Platform. OrderBazaar does not endorse the sale or purchase of any
              products or services on the Platform and accepts no liability for
              errors or omissions.
            </p>

            <h5>4. Non-performance or Breach</h5>
            <p>
              OrderBazaar is not responsible for any non-performance or breach
              of any contract between Buyers and Sellers. It cannot guarantee
              that Buyers and/or Sellers will perform any transaction concluded
              on the Platform, and it is not required to mediate or resolve
              disputes.
            </p>

            <h5>5. No Title or Possession</h5>
            <p>
              At no point during any transaction does OrderBazaar come into
              possession of the products or services offered by Sellers, nor
              does it gain title to or have any rights or claims over them.
              OrderBazaar does not hold any right, title, or interest over the
              products, and it has no obligations or liabilities in respect of
              the contract entered into between Buyers and Sellers.
            </p>

            <h4>Eligibility</h4>
            <p>
              The use of the OrderBazaar platform and services is available only
              to individuals and companies who can form legally binding
              contracts under applicable law. The Platform is not available to
              children (persons under the age of 18). If you are registering as
              a business entity, you represent that you have the eligibility to
              enter into an agreement and the authority to bind the entity to
              this Agreement. We reserve the right to revoke any and all
              licenses under this Agreement if any information provided is found
              to be incorrect.
            </p>

            <h4>User Obligations</h4>
            <h5>1. Product Compliance</h5>
            <p>
              You shall ensure that all products listed by you on the Platform
              comply with applicable laws and regulations. You shall be solely
              responsible for the accuracy and completeness of the product
              listings.
            </p>

            <h5>2. Stock Availability</h5>
            <p>
              You shall ensure that the products listed by you are in stock and
              available for sale. If any product is out of stock, you shall
              promptly remove the listing from the Platform.
            </p>

            <h5>3. Shipping and Delivery</h5>
            <p>
              You shall be responsible for shipping the products to customers in
              accordance with the delivery timelines specified on the Platform.
              Products must be packaged in a safe and secure manner and
              delivered without any damage.
            </p>

            <h5>4. Taxes and Licenses</h5>
            <p>
              You shall be solely responsible for all taxes, duties, and other
              levies applicable to the sale of your products. You are also
              responsible for obtaining all necessary licenses and permits for
              selling your products on the Platform.
            </p>

            <h5>5. Intellectual Property Rights</h5>
            <p>
              You represent and warrant that you own or have the necessary
              licenses, rights, consents, and permissions to use and authorize
              the use of any content or materials provided by you for use on the
              Platform.
            </p>

            <h4>Compliance as ONDC Network Participant</h4>
            <h5>1. Adherence to ONDC Regulations</h5>
            <p>
              OrderBazaar, as a participant within the ONDC network, adheres to
              prevailing ONDC regulations, rules, and directives. You agree to
              comply with all ONDC regulations, which may relate to catalog
              quality, order fulfillment, delivery, cancellations, or customer
              grievances.
            </p>

            <h5>2. Information Sharing</h5>
            <p>
              You authorize OrderBazaar to share information and content with
              ONDC and its associated entities as required by ONDC guidelines.
            </p>

            <h5>3. Data Standardization</h5>
            <p>
              OrderBazaar undertakes the tasks of adjusting, altering, and
              disseminating data in accordance with the standardized format
              prescribed by the ONDC protocol.
            </p>

            <h4>Fees and Payment</h4>
            <h5>1. Payment of Fees</h5>
            <p>
              You agree to pay all fees associated with the sale of your
              products and/or services on the Platform, as set forth in the
              Company's policies and procedures.
            </p>

            <h5>2. Remittance of Payment</h5>
            <p>
              We will remit payment for sales of your products and/or services
              on the Platform, less any applicable fees, in accordance with our
              policies and procedures.
            </p>

            <h4>Order Cancellation</h4>
            <p>
              By the established terms of the ONDC Network, measures are
              implemented to ensure the prompt delivery and quality of orders.
              The Buyer Network Participant (BNP) reserves the right to cancel
              orders that surpass the agreed delivery time (TAT) provided by the
              Seller. Sellers are obligated to adhere to the designated TAT and
              promptly update order statuses on the OrderBazaar Panel.
            </p>

            <h4>Shipping and Delivery</h4>
            <h5>1. Timely Delivery</h5>
            <p>
              You agree to ship and deliver products to customers in a timely
              and professional manner, in accordance with our policies and
              procedures.
            </p>

            <h5>2. Shipping Charges</h5>
            <p>
              OrderBazaar enables ONDC Network's logistics providers by default,
              allowing sellers to fetch real-time rates and ship orders. Sellers
              have the option to specify their own shipping charges and use
              their preferred shipping partners. If you opt for ONDC Network's
              logistics providers, you are responsible for all shipping and
              delivery costs.
            </p>

            <h4>Indemnification</h4>
            <p>
              You agree to indemnify and hold Orderbazaar e-Commerce Pvt. Ltd.,
              its suppliers, service providers, officers, directors, agents, and
              employees harmless from any and all losses, costs, liabilities, or
              expenses arising out of or in connection with your breach of the
              User Agreement, violation of any law, or the rights of a third
              party.
            </p>

            <h4>Limitation of Liability</h4>
            <p>
              In no event shall Orderbazaar e-Commerce Pvt. Ltd., its suppliers,
              or service providers be liable for lost profits or any special,
              incidental, or consequential damages arising out of or in
              connection with the Platform, Services, or this Agreement. Our
              cumulative liability to you or any third party in any
              circumstances shall be limited to the payment received by
              Orderbazaar e-Commerce Pvt. Ltd. for the specific service.
            </p>

            <h4>Force Majeure</h4>
            <p>
              Neither party shall be held liable for any failure or delay in the
              performance of any obligations under this Agreement if such
              failure or delay is caused by circumstances beyond their
              reasonable control, including acts of God, natural disasters, war,
              terrorism, government regulations, strikes, or any similar event.
            </p>

            <h4>Applicable Law</h4>
            <p>
              These terms are governed by and construed in accordance with the
              laws of India. Any dispute arising out of or relating to the use
              of the OrderBazaar platform shall be submitted to the exclusive
              jurisdiction of the courts in Chennai, India.
            </p>

            <h4>About OrderBazaar</h4>
            <p>
              <Link href="#">OrderBazaar.in</Link> is a hyper-local e-commerce
              platform connected to the ONDC network, built to connect local
              shops with customers across India. As a participant in the ONDC
              network, OrderBazaar offers a comprehensive platform for sellers
              to manage their products, orders, and customer interactions
              seamlessly.
            </p>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
