import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppLayout from "../appLayout";
import NavBar from "../appLayout/navbar/navbar";
import Button from "../shared/button/button";
import { buttonTypes } from "../shared/button/utils";
import {
  Box,
  MenuItem,
  Stack,
  Typography,
  Select,
  Checkbox,
  ListSubheader,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import styles from "../../styles/searchby/searchByCity.module.scss";

const statesData = require("./states.json");
const citiesData = require("./citiesByState.json");

export default function SearchByCity() {
  const [formData, setFormData] = useState({ states: [], cities: [] });
  const [availableCities, setAvailableCities] = useState([]);
  const history = useHistory();

  // Update available cities when states are selected
  useEffect(() => {
    if (formData.states.length === 0) {
      setAvailableCities([]);
      return;
    }
    const updatedCities = formData.states.flatMap((state) => {
      const stateCities = citiesData[state] || {};
      return {
        state,
        cities: Object.entries(stateCities).map(([key, name]) => ({
          key, // Use key (numerical value) as identifier
          name,
        })),
      };
    });
    setAvailableCities(updatedCities);
  }, [formData.states]);

  // Handle state change
  const handleStateChange = (event) => {
    const { value } = event.target;
    setFormData((prev) => ({
      ...prev,
      states: Array.isArray(value) ? value : value.split(","),
    }));
  };

  // Handle city change (store keys instead of names)
  const handleCityChange = (event) => {
    const { value } = event.target;
    setFormData((prev) => ({
      ...prev,
      cities: Array.isArray(value) ? value : value.split(","),
    }));
  };

  // Submit form and pass states and city keys in the URL
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.states.length === 0) {
      alert("Please select at least one state.");
      return;
    }
    if (formData.cities.length === 0) {
      alert("Please select at least one city.");
      return;
    }

    // Update URL params
    const params = new URLSearchParams({});
    if (formData.states.length > 0) {
      params.set("states", JSON.stringify(formData.states)); // Encode states array
    }
    if (formData.cities.length > 0) {
      params.set("cities", JSON.stringify(formData.cities)); // Encode cities array      
    }

    // Navigate with updated params
    history.push(`/application/products?${params.toString()}`);
  };

  const handleReset = () => {
    setFormData({ states: [], cities: [] });
    setAvailableCities([]);
  };

  return (
    <AppLayout>
      <NavBar formData={formData} /> {/* Pass formData to NavBar */}
      <form onSubmit={handleSubmit} className={styles.searchForm}>
        <Stack spacing={4} alignItems="center">
          <Stack direction="row" width={600} spacing={2} alignItems="center">
            <Typography variant="h6" fontWeight={400} flex={0.6}>
              Select State(s):
            </Typography>
            <Box flex={1}>
              <Select
                multiple
                value={formData.states}
                onChange={handleStateChange}
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  selected
                    .map((key) => {
                      const city = availableCities
                        .flatMap(({ cities }) => cities)
                        .find((c) => c.key === key);
                      return city ? city.name : key;
                    })
                    .join(", ")
                }
                className={styles.formControl}
              >
                {statesData.map((state) => (
                  <MenuItem key={state} value={state}>
                    <Checkbox checked={formData.states.includes(state)} />
                    <ListItemText primary={state} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Stack>

          <Stack direction="row" width={600} spacing={2} alignItems="center">
            <Typography variant="h6" fontWeight={400} flex={0.6}>
              Select City(s):
            </Typography>
            <Box flex={1}>
              <Select
                multiple
                value={formData.cities}
                onChange={handleCityChange}
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  selected
                    .map((key) => {
                      // Map city keys back to names for display
                      const city = availableCities
                        .flatMap(({ cities }) => cities)
                        .find((c) => c.key === key);
                      return city ? city.name : key;
                    })
                    .join(", ")
                }
                className={styles.formControl}
              >
                {availableCities.flatMap(({ state, cities }) => [
                  <ListSubheader key={`header-${state}`}>
                    {state}
                  </ListSubheader>,
                  ...cities.map((city) => (
                    <MenuItem key={city.key} value={city.key}>
                      {/* Use city key as value */}
                      <Checkbox checked={formData.cities.includes(city.key)} />
                      <ListItemText primary={city.name} />
                    </MenuItem>
                  )),
                ])}
              </Select>
            </Box>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              button_type={buttonTypes.primary}
              button_text="Submit"
              type="submit"
            />
            <Button
              button_type={buttonTypes.secondary}
              button_text="Reset"
              onClick={handleReset}
              type="button"
            />
          </Stack>
        </Stack>
      </form>
    </AppLayout>
  );
}
