import Cookies from "js-cookie";

export function AddCookie(key, value) {
  const cookie_expiry_time = new Date();
  cookie_expiry_time.setTime(cookie_expiry_time.getTime() + 3600 * 1000); // expires in 1 hour
  Cookies.set(key, value, {
    expires: cookie_expiry_time,
  });
}

export function getValueFromCookie(key) {
  return Cookies.get(key);
}

export function removeCookie(key) {
  Cookies.remove(key);
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    const trimmedName = name.trim();

    // Print the name of the cookie being deleted
    console.log(`Deleting cookie: ${trimmedName}`);

    Cookies.remove(trimmedName);
  });

  console.log("All cookies have been deleted.");
}


//export function deleteAllCookies() {
//  const cookies = document.cookie.split(";");
//  cookies.map((cookie) => {
//    const eqPos = cookie.indexOf("=");
//    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
//    Cookies.remove(name.trim());
//    return null;
//  });
//}
