import React, { useState, useEffect } from "react";
import useStyles from "./style";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CartIcon } from "../../../assets/images/cart.svg";
import no_image_found from "../../../assets/images/no_image_found.png";
import { useHistory } from "react-router-dom";
import { postCall } from "../../../api/axios";
import { getValueFromCookie } from "../../../utils/cookies";
import { Button, Stack } from "@mui/material";

const ProductGridView = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    product,
    productId,
    price,
    bpp_id,
    location_id,
    bpp_provider_id,
    bpp_provider_descriptor,
    show_quantity_button = true,
    onUpdateCart = () => { },
    handleAddToCart = () => { },
    getProductDetails,
    productLoading,
  } = props;
  const { id, descriptor, provider_details } = product;
  const { name: provider_name } = bpp_provider_descriptor;
  const { name: product_name, images, symbol } = descriptor;

  return (
    <div
      className={classes.productItemContainer}
      onClick={() =>
        history.push(`/application/products?productId=${productId}`)
      }
    >
      <Card>
        <Stack sx={{
          minHeight: { xs: 340, md: 340 },
          maxHeight: { xs: 340, md: 340 }
        }} p={2} justifyContent="space-between">
          <img
            className={classes.productImage}
            src={symbol ? symbol : no_image_found}
            alt={`sub-cat-img-${bpp_id}`}
            sx={{
              width: 200, // Fixed width
              height: 200, // Fixed height
              objectFit: "cover", // Ensures the image covers the area proportionally
              margin: "auto",
            }}
          />
          {/* <Button
          fullWidth
          className={classes.buyNowButton}
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            // getProductDetails(productId).then((data) => handleAddToCart(data, true, true));
          }}
        >
          Buy Now
        </Button> */}
          <Stack spacing={1}>
            <Typography variant="h6" sx={{ fontSize: 14 }}>
              {product_name}
            </Typography>
            <Typography variant="subtitle1">{provider_name}</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" className={classes.priceTypo}>
                {`₹${Number.isInteger(Number(price?.value))
                  ? Number(price?.value).toFixed(2)
                  : Number(price?.value).toFixed(2)
                  }`}
              </Typography>
            </Stack>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Button variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  getProductDetails(productId).then((data) =>
                    handleAddToCart(data, true)
                  );
                }} size="small">
                Add to cart
              </Button>
            </div>
          </Stack>

        </Stack>
      </Card>
    </div>
  );
};

export default ProductGridView;
