import React from "react";
import { Link, Typography } from "@mui/material";

import AppLayout from "../appLayout";
import styles from "../../styles/services/services.module.scss";

export default function ContactUs() {
  return (
    <AppLayout>
      <div className="container pt-5" style={{ paddingBottom: "5em" }}>
        <div className="row align-items-center">
          <div className={styles.subContainer}>
            <Typography
              variant="h1"
              sx={{
                width: "100%",
                justifyItems: "center",
                textAlign: "center",
              }}
            >
              Contact Us
            </Typography>
            <p className="mt-5">
              We're here to help! If you have any questions, concerns, or
              feedback, please don't hesitate to reach out to us. You can
              contact us through any of the following methods, and our support
              team will be happy to assist you.
            </p>

            <h4>Our Contact Information</h4>

            <h5>Email</h5>
            <p>
              For general inquiries, order-related questions, or support, please
              email us at:
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <Link href="mailto:contact@orderbazaar.in">
                contact@orderbazaar.in
              </Link>
            </p>

            <h5>Phone</h5>
            <p>
              If you prefer to speak with someone, you can reach our customer
              support team at:
            </p>
            <p>
              <strong>Phone:</strong> +91 9841165519
            </p>

            <h5>Mailing Address</h5>
            <p>You can also send us mail at our office address:</p>
            <p>
              Orderbazaar e-Commerce Pvt. Ltd.
              <br />
              E001, Bollineni Hillside - Phase 2, Nookampalayam Link Road,
              <br />
              Perumbakkam, Chennai - 600 100
              <br />
              India
            </p>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
