import React from "react";
import { Link, Typography } from "@mui/material";

import AppLayout from "../appLayout";
import styles from "../../styles/services/services.module.scss";

export default function AboutUs() {
  return (
    <AppLayout>
      <div className="container pt-5" style={{ paddingBottom: "5em" }}>
        <div className="row align-items-center">
          <div className={styles.subContainer}>
            <Typography
              variant="h1"
              sx={{
                width: "100%",
                justifyItems: "center",
                textAlign: "center",
              }}
            >
              About Us
            </Typography>
            <p className="mt-3">
              Welcome to OrderBazaar, your trusted hyper-local e-commerce
              platform designed to connect local shops with customers across
              India. We are proud to be a participant in the Open Network for
              Digital Commerce (ONDC), a government-led initiative aimed at
              democratizing digital commerce by empowering small and medium
              businesses.
            </p>

            <h4>Our Mission</h4>
            <p>
              At OrderBazaar, our mission is to bridge the gap between local
              businesses and customers by providing a seamless, easy-to-use
              platform that brings the convenience of online shopping to every
              corner of India. We believe in supporting local communities and
              helping small businesses thrive in the digital age. Our platform
              is designed to make it easier for customers to discover and
              purchase products from nearby stores, while also offering
              businesses the tools they need to grow and succeed.
            </p>

            <h4>What We Offer</h4>
            <p>
              OrderBazaar offers a comprehensive suite of services to both
              buyers and sellers, making the shopping experience as smooth and
              efficient as possible:
            </p>
            <ul>
              <li>
                <strong>For Buyers:</strong> We offer a wide range of products
                from local stores, ensuring that you can find what you need from
                the comfort of your home. Our platform allows you to browse
                products, compare prices, and enjoy convenient delivery options.
              </li>
              <li>
                <strong>For Sellers:</strong> We provide a robust platform that
                enables local businesses to reach a broader audience. Our seller
                dashboard offers tools for managing products, tracking orders,
                and engaging with customers, all in one place. We also support
                integration with ONDC protocols, ensuring compliance with
                national standards.
              </li>
            </ul>

            <h4>Why Choose Us?</h4>
            <p>
              OrderBazaar is more than just an e-commerce platform. We are
              committed to:
            </p>
            <ul>
              <li>
                <strong>Empowering Local Businesses:</strong> By providing a
                platform tailored to the needs of local vendors, we help small
                businesses compete with larger online retailers.
              </li>
              <li>
                <strong>Supporting Communities:</strong> We believe in keeping
                commerce local. By shopping with OrderBazaar, you are directly
                supporting your community and helping local businesses grow.
              </li>
              <li>
                <strong>Innovative Technology:</strong> Our platform leverages
                the latest technology to provide a seamless and secure shopping
                experience for both buyers and sellers.
              </li>
              <li>
                <strong>Customer Satisfaction:</strong> We prioritize customer
                satisfaction by offering a user-friendly platform, reliable
                customer support, and a wide selection of products from trusted
                local vendors.
              </li>
            </ul>

            <h4>Our Vision</h4>
            <p>
              Our vision is to become India’s leading hyper-local e-commerce
              platform, making it easier for customers to shop from local
              businesses and for sellers to expand their reach. We aim to create
              a marketplace where every local shop, no matter how small, can
              compete on a level playing field with the biggest e-commerce
              giants.
            </p>

            <h4>Contact Us</h4>
            <p>
              If you have any questions or would like to learn more about how
              OrderBazaar can help your business, please do not hesitate to
              reach out to us:
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <Link href="mailto:ops@orderbazaar.in">ops@orderbazaar.in</Link>
            </p>
            <p>
              <strong>Phone:</strong> +91 9841165519
            </p>
            <p>
              <strong>Address:</strong> Orderbazaar e-Commerce Pvt. Ltd., #22,
              1st Floor, Vijay Avenue, 6th Street, Sankarapuram, Sithalapakkam,
              Chennai - 600 126
            </p>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
